module.exports = {
	privacy: {
		title: "Gizlilik Metni",
		content: `<p style='margin-top:0in;margin-right:159.4pt;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;font-family:"Times New Roman",serif;'>&Uuml;YELİK S&Ouml;ZLEŞMESİ</span></strong></p>
    <p style='margin-top:0in;margin-right:5.4pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;font-family:"Times New Roman",serif;'>1-TARAFLAR</span></strong></p>
    <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.4pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>HİZMET SAĞLAYICI&nbsp; &nbsp; &nbsp; &nbsp;:</span></strong><a href="http://www.dikotr.com"><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>www.dikotr.com</span></strong></a><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>internet sitesinin faaliyetlerini y&uuml;r&uuml;ten&nbsp;</span><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&ldquo;&Ouml;merağa Mah. Ak&ccedil;acami Cad. Turhan Apartmanı No:20 İ&ccedil; kapı no:5 İzmit/Kocaeli adresinde mukim GENOVA EĞİTİM VE BİLİŞİM HİZMETLERİ LTD ŞTİ (bundan b&ouml;yle kısaca DİKO olarak anılacaktır.)&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.4pt;margin-bottom:0in;margin-left:5.6pt;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.4pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>KULLANICI&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;:&nbsp;</span></strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>DİKO internet sitesine &uuml;ye olmak i&ccedil;in işbu &Uuml;yelik S&ouml;zleşmesi ile ayrılmaz par&ccedil;ası olan eklerine&nbsp;</span><a href="http://www.dikotr.com"><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>www.dikotr.com</span></strong></a><strong><u><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></u></strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>internet sitesinde yer alan şartlar ile kurallara onay veren &Uuml;YE</span></p>
    <p style='margin-top:9.65pt;margin-right:0in;margin-bottom:0in;margin-left:18.1pt;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:-18.1pt;'><strong><span style='font-size:16px;font-family:"Times New Roman",serif;'>2-TANIMLAR</span></strong></p>
    <p style='margin-top:0in;margin-right:5.35pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
    <p style='margin-top:0in;margin-right:5.35pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&ldquo;İnternet&nbsp;Sitesi &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;:</span></strong><a href="http://www.dikotr.com"><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>www.dikotr.com</span></strong></a><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>simli alan adından ve bu alan adına bağlı alt alan adlarından ulaşılabilen &ldquo; DİKO&rsquo;nun iş bu s&ouml;zleşme ile kapsamı belirlenen &ldquo;Hizmetler&rdquo;ini sunduğu internet&nbsp;sitesi.</span></p>
    <p style='margin-top:9.7pt;margin-right:0in;margin-bottom:0in;margin-left:5.6pt;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;font-family:"Times New Roman",serif;'>&ldquo;Kullanıcı/Kullanıcılar&rdquo;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;:&nbsp;</span></strong><span style='font-size:16px;font-family:"Times New Roman",serif;'>&ldquo;İnternet Sitesi&rdquo;ne erişen her ger&ccedil;ek veya t&uuml;zel&nbsp;kişi/kişiler.</span></p>
    <p style='margin-top:.35pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.35pt;margin-bottom:0in;margin-left:5.6pt;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:-.05pt;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&ldquo;&Uuml;ye/&Uuml;yeler&rdquo;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;:&nbsp;</span></strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>İş bu s&ouml;zleşme koşulları doğrultusunda &ldquo;İnternet Sitesi&rdquo;ne &uuml;ye olan ve &quot;İnternet Sitesi&quot; dahilinde sunulan hizmetlerden ve&nbsp; DİKO&rsquo;nun belirlediği koşullar dahilinde yararlanan &ldquo;Kullanıcı&rdquo;.</span></p>
    <p style='margin-top:9.75pt;margin-right:5.2pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;&ldquo;&Uuml;yelik&rdquo;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;:&nbsp;</span></strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&ldquo;&Uuml;ye&rdquo; olmak isteyen &ldquo;Kullanıcı&rdquo;nın, &ldquo;İnternet Sitesi&rdquo;ndeki &uuml;yelik formunu doğru ve ger&ccedil;ek bilgilerle doldurması, verdiği kimlik bilgilerinin &nbsp;&lsquo; DİKO &rdquo; tarafından onaylanması ve bildirimi ile kazanılan stat&uuml;d&uuml;r. &Uuml;yelik işlemleri tamamlanmadan &ldquo;&Uuml;ye&rdquo; olma hak ve&nbsp;yetkisine sahip olunamaz. &ldquo;&Uuml;yelik&rdquo; hak ve y&uuml;k&uuml;ml&uuml;l&uuml;kleri, başvuruda bulunana ait olan, kısmen veya tamamen herhangi bir &uuml;&ccedil;&uuml;nc&uuml; şahsa devredilemeyen hak ve y&uuml;k&uuml;ml&uuml;l&uuml;klerdir. &ldquo;&Uuml;yelik&rdquo; başvurusu herhangi bir sebep g&ouml;sterilmeksizin &ldquo;DİKO &rdquo; tarafından reddedilebilir veya ek şart ve&nbsp;koşullar talep edilebilir. &ldquo;DİKO &rdquo; gerekli g&ouml;rmesi halinde &ldquo;&Uuml;ye&rdquo;nin &ldquo;&Uuml;yelik&rdquo; stat&uuml;s&uuml;n&uuml; sona erdirebilir, &ldquo;&Uuml;yelik&rdquo;i herhangi bir sebeple&nbsp;sona erenin sonradan yapacağı &ldquo;&Uuml;yelik&rdquo; başvurusunu kabul etmeyebilir. &ldquo;DİKO&rdquo; gerekli g&ouml;rmesi halinde gerek&ccedil;e g&ouml;stermeden &uuml;yeliği koşulsuz ve&nbsp;şartsız olarak&nbsp;sonlandırabilir.</span></p>
    <p style='margin-top:9.75pt;margin-right:5.2pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&quot;&Uuml;yelik&nbsp;S&ouml;zleşmesi&quot;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;:&nbsp;</span></strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&ldquo;İnternet Sitesi&rdquo;nde yer alan şartlar ve&nbsp;koşullar ile eklerden oluşan, kullanıcının &ldquo;&Uuml;yelik S&ouml;zleşmesi&rdquo;ni anladığına ve&nbsp;kendi rızası ile kabul ettiğine ilişkin elektronik olarak verdiği onay neticesinde elektronik ortamda akdedilen, ayrılmaz par&ccedil;ası &nbsp;olan ekler ile &ldquo;İnternet Sitesi&rdquo;nde yer alan şart ve kurallarla birlikte bir b&uuml;t&uuml;n olan elektronik&nbsp;&nbsp;s&ouml;zleşme.</span></p>
    <p style='margin-top:9.75pt;margin-right:5.2pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&quot;DİKO &nbsp;&Uuml;yelik&nbsp;Hesabı&quot;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;:&nbsp;</span></strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&ldquo;&Uuml;ye&rdquo;nin &ldquo;İnternet Sitesi&rdquo; i&ccedil;erisinde sunulan hizmetlerden yararlanmak i&ccedil;in gerekli olan iş ve işlemleri ger&ccedil;ekleştirdiği, kişisel bilgilerini ve&nbsp;uygulama bazında kendisinden talep eden bilgilerini girdiği, &Uuml;yelik&rdquo;le ilgili konularda &quot;DİKO&quot;ya talepte &quot;&Uuml;yelik bilgilerini g&uuml;ncelleyip, sunulan hizmetlerle ilgili raporlamaları g&ouml;r&uuml;nt&uuml;leyebildiği, kendisinin belirlediği ve m&uuml;nhasıran kendisi tarafından kullanılacağını taahh&uuml;t ettiği &quot;kullanıcı adı&quot; ve &quot;şifre&quot; ile &ldquo;İnternet Sitesi&rdquo; &uuml;zerinden eriştiği &quot;&Uuml;ye&quot;ye &ouml;zel internet sayfaları&nbsp;b&uuml;t&uuml;n&uuml;</span></p>
    <p style='margin-top:3.25pt;margin-right:5.25pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&ldquo;DİKO Hizmetleri&rdquo; (&quot;Hizmetler&quot;) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;:&nbsp;</span></strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&ldquo;İnternet Sitesi&rdquo; i&ccedil;erisinde &quot;&Uuml;ye&quot;nin işbu &ldquo;&Uuml;yelik S&ouml;zleşmesi&rdquo; i&ccedil;erisinde tanımlı olan iş ve işlemlerini ger&ccedil;ekleştirmelerini sağlamak amacıyla &ldquo;DİKO &rdquo; tarafından sunulan uygulamalardır. &ldquo;DİKO &rdquo;, &ldquo;İnternet Sitesi&rdquo; i&ccedil;erisinde sunulan &quot;Hizmetler&quot;inde dilediği zaman değişiklikler ve/veya uyarlamalar yapabilir. Yapılan değişiklikler ve/veya uyarlamalarla ilgili &quot;&Uuml;ye&quot;nin uymakla y&uuml;k&uuml;ml&uuml; olduğu kural ve&nbsp;koşullar &ldquo;İnternet &nbsp;Sitesinden&rdquo; &quot;&Uuml;ye&quot;ye duyurulur, a&ccedil;ıklanan şartlar ve koşullar &ldquo;İnternet Sitesinde&rdquo; yayımlandığı tarihte y&uuml;r&uuml;rl&uuml;ğe girer.</span></p>
    <p style='margin-top:3.25pt;margin-right:5.25pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&ldquo;İ&ccedil;erik&rdquo;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;:&nbsp;</span></strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&ldquo;İnternet Sitesinde&rdquo; yayınlanan ve&nbsp;erişimi m&uuml;mk&uuml;n olan her t&uuml;rl&uuml; bilgi, yazı, dosya, resim, video, rakam vb. g&ouml;rsel, yazımsal ve işitsel&nbsp;imgeler.</span></p>
    <p style='margin-top:9.25pt;margin-right:5.4pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>DİKO &nbsp;Aray&uuml;z&uuml;&quot;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;:&nbsp;</span></strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>DİKO ve &quot;&Uuml;ye&quot;ler tarafından oluşturulan i&ccedil;eriğin &quot;Kullanıcı&rdquo;lar tarafından g&ouml;r&uuml;nt&uuml;lenebilmesi ve&nbsp;DİKO Veritabanı&quot;ından sorgulanabilmesi amacıyla &quot;Kullanıcı&rdquo;lar tarafından kullanılan; &ldquo;İnternet Sitesi&rdquo; &uuml;zerinden yapılabilecek her t&uuml;rl&uuml; işlemin ger&ccedil;ekleştirilmesi i&ccedil;in bilgisayar programına komut veren internet&nbsp;sayfaları.</span></p>
    <p style='margin-top:9.5pt;margin-right:5.6pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>DİKO Veritabanı&rdquo;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;:&nbsp;</span></strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&ldquo;İnternet Sitesi&rdquo; dahilinde erişilen i&ccedil;eriklerin depolandığı, tasnif edildiği, sorgulanabildiği ve erişilebildiği &ldquo;&ldquo;DİKO &rdquo; ya ait olan 5846 Sayılı Fikir ve Sanat Eserleri Kanunu gereğince korunan&nbsp;veritabanıdır.</span></p>
    <p style='margin-top:9.7pt;margin-right:5.4pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>Kullanıcı&nbsp;Giriş&rdquo;&nbsp;sayfası&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;:&nbsp;</span></strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&quot;&Uuml;ye&quot;nin &quot;İnternet sitesinde&quot; yer alan &ccedil;eşitli uygulamalardan ve&nbsp;&quot;Hizmetler&rdquo;den yararlanabilmesi i&ccedil;in gerekli işlemleri ger&ccedil;ekleştirebildiği, kişisel bilgilerini, tercihlerini, uygulama bazında kendisinden talep eden bilgileri girdiği, sadece &quot;&Uuml;ye&quot; tarafından belirlenen kullanıcı adı ve şifre ile erişilebilen &quot;&Uuml;ye&quot;ye &ouml;zel&nbsp;sayfa.</span></p>
    <p style='margin-top:9.7pt;margin-right:5.4pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>3-&Uuml;YELİK S&Ouml;ZLEŞMESİNİN KONUSU VE KAPSAMI</span></strong></p>
    <p style='margin-top:9.7pt;margin-right:5.4pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>İşbu &ldquo;&Uuml;yelik S&ouml;zleşmesinin konusu, &quot;&ldquo;İnternet Sitesinde sunulan &ldquo;Hizmetlerin, bu Hizmetlerden yararlanma şartları ile tarafların hak ve y&uuml;k&uuml;ml&uuml;l&uuml;klerinin tespitidir. &ldquo;&Uuml;yelik S&ouml;zleşmesi&rdquo; ve ekleri ile &ldquo;İnternet Sitesi&rdquo; i&ccedil;erisinde yer alan kullanıma, &uuml;yeliğe ve Hizmetlere ilişkin &ldquo;DİKO &rdquo; tarafından yapılan t&uuml;m uyarı, bildirim, uygulama ve a&ccedil;ıklama gibi beyanlar kapsam d&acirc;hilindedir. &quot;Kullanıcı&quot;, işbu &ldquo;&Uuml;yelik S&ouml;zleşmesi&rdquo; h&uuml;k&uuml;mlerini kabul etmekle, &ldquo;İnternet Sitesi&rdquo; i&ccedil;inde yer alan kullanıma, &uuml;yeliğe ve hizmetlere ilişkin &ldquo;DİKO&rdquo; tarafından a&ccedil;ıklanan her t&uuml;rl&uuml; beyana uygun davranacağını kabul ve taahh&uuml;t etmektedir.</span></p>
    <p style='margin-top:9.7pt;margin-right:5.4pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>4-&Uuml;YELİK ŞARTLARI</span></strong></p>
    <p style='margin-top:.3pt;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>4.1</span></strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&ldquo;İnternet Sitesine &uuml;ye olabilmek i&ccedil;in reşit olmak ve&nbsp;&ldquo;DİKO &rdquo; tarafından işbu &ldquo;&Uuml;yelik S&ouml;zleşmesi&rdquo; kapsamında ge&ccedil;ici olarak &uuml;yelikten uzaklaştırılmamış, &uuml;yeliği askıya alınmamış veya &uuml;yelikten s&uuml;resiz yasaklanmamış olmak gerekmektedir. &ldquo;&Uuml;yelik stat&uuml;s&uuml;n&uuml;n kazanılması i&ccedil;in, &ldquo;&Uuml;ye&rdquo; olmak isteyen &ldquo;Kullanıcının &ldquo;Sitede bulunan &uuml;yelik formunu, &uuml;yelik formunda talep edilen bilgiler doğrultusunda ger&ccedil;ek bilgilerle doldurması ve &uuml;yelik başvurusunun &ldquo;DİKO&rdquo; tarafından değerlendirilerek onaylanması gerekmektedir. &ldquo;DİKO&rdquo;, tamamen kendi iradesi doğrultusunda ve herhangi bir sebebe dayanmadan &ldquo;&Uuml;yelik başvurularını reddedebilir veya &ldquo;&Uuml;yelik başvurusunun kabul edilmesini ek şart ve&nbsp;koşullara &nbsp;bağlayabilir.</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>4.2</span></strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&ldquo;DİKO &rdquo; herhangi bir zamanda gerek&ccedil;e g&ouml;stermeden, bildirimde bulunmadan, tazminat, ceza vb. sair y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml; bulunmaksızın derhal y&uuml;r&uuml;rl&uuml;ğe girecek şekilde işbu &ldquo;&Uuml;yelik S&ouml;zleşmesini tek taraflı olarak feshedebilir, &quot;&Uuml;yenin &ldquo;&Uuml;yelik durumunu sonlandırabilir veya ge&ccedil;ici olarak durdurabilir. &quot;İnternet Sitesinde bulunduğu belirtilen kurallara aykırılık halleri, &ldquo;&Uuml;yenin &quot;DİKO&quot; bilgi g&uuml;venliği sistemine risk oluşturması halleri &uuml;yeliğe son verme veya &uuml;yeliği ge&ccedil;ici durdurma hallerindendir.</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>4.3</span></strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&ldquo;DİKO&rdquo;; işbu s&ouml;zleşme ve &ldquo;İnternet sitesinde&rdquo; belirtilen kural ve koşullara aykırılık, &ldquo;&Uuml;yelik başvurusu sırasında verilen bilgilerin yeterli, doğru veya g&uuml;ncel olmadığının tespit edilmesi, &ldquo;&Uuml;yelik başvurusunda bulunan kişinin başvurusunun daha &ouml;nce reddedilmiş olması, &ldquo;&Uuml;yenin ger&ccedil;ekleştirdiği işlemlerin &ldquo;DİKO&rdquo; i&ccedil;in hukuki, teknik ve&nbsp;&ouml;zellikle bilgi g&uuml;venliği anlamında risk oluşturduğunun tespit edilmesi sebepleri ve&nbsp;benzeri sebepler de dahil olmak &uuml;zere, haklı bir sebebe dayanarak veya herhangi bir sebebe dayanmadan ve&nbsp;herhangi bir bildirimde bulunmadan, her zaman herhangi bir tazminat y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml; altında bulunmaksızın &ldquo;&Uuml;yenin &uuml;yeliğine son verebilir.</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>5-TARAFLARIN HAK VE Y&Uuml;K&Uuml;ML&Uuml;L&Uuml;KLERİ</span></strong></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>5.1.:&ldquo;&Uuml;ye&quot;, &ldquo;İnternet Sitesinde belirtilen kurallara, beyanlara, y&uuml;r&uuml;rl&uuml;kteki t&uuml;m mevzuata ve&nbsp;ahlak kurallarına uygun hareket edeceğini, &ldquo;&Uuml;yelik S&ouml;zleşmesi&rdquo; h&uuml;k&uuml;mleri ile &ldquo;İnternet Sitesindeki t&uuml;m şart ve kuralları anladığını ve onayladığını kabul ve&nbsp;taahh&uuml;t&nbsp;etmektedir.</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>5.2:&quot;&Uuml;ye&rdquo;, &ldquo; DİKO&rsquo;nun y&uuml;r&uuml;rl&uuml;kteki mevzuat h&uuml;k&uuml;mleri gereğince resmi makamlara a&ccedil;ıklama yapmakla y&uuml;k&uuml;ml&uuml; olduğu durumlarda; &ldquo;&Uuml;yelere ait gizli/&ouml;zel/ticari bilgileri resmi makamlara a&ccedil;ıklamaya yetkili olduğunu, bu sebeple &ldquo;DİKO&rsquo;dan her ne nam altında olursa olsun tazminat talep etmeyeceğini kabul ve taahh&uuml;t etmektedir. Bunun haricinde &ldquo;&Uuml;yenin &ldquo;İnternet Sitesi&rdquo; &uuml;zerinde verdiği ilanlarla ilgili olarak herhangi bir kişi ya da kurumun haklarının ihlal edildiği iddiası ile DİKO&rsquo;ya bildirimde bulunması, yargı yoluna başvuracağını bildirmesi halinde; &ldquo;&Uuml;yenin kendisine bildirdiği ad-soyad bilgisini &ldquo;DİKO ilgili tarafa verebilir. Bu durumda &ldquo; DİKO&rsquo; nun hukuki veya cezai sorumluluğu&nbsp;bulunmamaktadır.</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>5.3:&quot;&Uuml;yelerin, &ldquo;DİKO &rdquo; &Uuml;yelik Hesabına girişte kullandıkları &quot;kullanıcı adı&quot; ve &quot;şifrenin g&uuml;venliğini sağlamaları, m&uuml;nhasıran ve&nbsp;m&uuml;nferiden kendileri tarafından kullanılmasını temin etmeleri, &uuml;&ccedil;&uuml;nc&uuml; kişilerden saklamaları tamamen kendi sorumluluğundadır. Bu konuda ihmal veya kusurlarından dolayı diğer &ldquo;&Uuml;yelerin ve/veya &ldquo;DİKO&rsquo;nun ve/veya &uuml;&ccedil;&uuml;nc&uuml; kişilerin uğradığı veya uğrayabileceği maddi ve/veya manevi her t&uuml;r zararlardan &ldquo;&Uuml;ye&rdquo;&nbsp;sorumludur.</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>5.4:&Uuml;ye&rdquo;; &ldquo;İnternet sitesinde&rdquo; yer alan hi&ccedil;bir bilginin ve&nbsp;i&ccedil;eriğin vasıta teknik yapısı, fiyatı veya başka bir konuda bir tavsiye, &ouml;neri vb. niteliğinde olmadığını, &ldquo;İnternet sitesinde&rdquo; yer alan uzman g&ouml;r&uuml;şleri de dahil olmak &uuml;zere t&uuml;m bilgilerin genel bilgi vermek amacıyla yer aldığını kabul ve beyan eder. &ldquo;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>5.5:&ldquo;&Uuml;ye&rdquo;, DİKO&rsquo;nun , &ldquo;İnternet sitesinde&rdquo; &ldquo;&Uuml;yeler ve &ldquo;Kullanıcılar tarafından sağlanan i&ccedil;erikler de dahil olmak &uuml;zere her t&uuml;rl&uuml; ilanın, bilginin, i&ccedil;eriğin ve&nbsp;g&ouml;rselin ger&ccedil;ekliğini, orijinalliğini, g&uuml;venliğini, doğruluğunu araştırma, bu i&ccedil;erik ve&nbsp;ilanların internet &uuml;zerinden teşhirinin hukuka uygun olup olmadığını tespit etme sorumluluğu bulunmadığını, s&ouml;z konusu ilan ve i&ccedil;eriklerden ve bunlar sebebiyle ortaya &ccedil;ıkabilecek zararlardan dolayı &ldquo;DİKO&rsquo;nun, &ldquo;DİKO &rdquo; &ccedil;alışanlarının ve y&ouml;neticilerinin sorumluluğu bulunmadığını kabul ve&nbsp;beyan&nbsp;eder.</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>5.6:&ldquo;&Uuml;ye&quot;, &ldquo;İnternet sitesinde&rdquo; kendisi tarafından y&uuml;klenen bilgi, i&ccedil;erik ve ilanların doğru ve&nbsp;hukuka uygun olduğunu, karalama, k&ouml;t&uuml;leme, ticari iftira atma, tehdit veya taciz etme gibi hukuka veya ahlaka aykırı hi&ccedil;bir sonu&ccedil; doğurmayacağını, bu bilgi ve i&ccedil;eriklerin &ldquo;İnternet sitesi&rdquo; &uuml;zerinde yayınlanmasının veya ilanlarda belirttiği vasıtalarının satışının ve teşhirinin y&uuml;r&uuml;rl&uuml;kteki mevzuat doğrultusunda herhangi bir hukuka aykırılık yaratmadığını, s&ouml;z konusu ilan ve&nbsp;i&ccedil;eriklerin&nbsp;ilgili &nbsp; &nbsp;olduğu mal ve &uuml;r&uuml;nlerin internet &uuml;zerinde satışa arz edilmesi i&ccedil;in yayınlanmasına ilişkin hakkı veya yetkisi olduğunu kabul ve taahh&uuml;t etmektedir.</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>5.7:&quot;&Uuml;ye&quot;, &quot;DİKO&rsquo;nun &nbsp; yazılı onayı olmadan işbu &ldquo;&Uuml;yelik S&ouml;zleşmesini veya bu &ldquo;&Uuml;yelik S&ouml;zleşmesinin kapsamındaki hak ve y&uuml;k&uuml;ml&uuml;l&uuml;klerini kısmen veya tamamen herhangi bir &uuml;&ccedil;&uuml;nc&uuml; kişiye&nbsp;devredemez.</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>5.8:&ldquo;&Uuml;ye&rdquo; hukuka uygun ama&ccedil;larla &quot;İnternet sitesi&rdquo; &uuml;zerinde işlem yapabilir. &quot;&Uuml;ye&quot;nin, &quot;İnternet sitesi&rdquo; dahilinde yaptığı her işlem ve&nbsp;eylemdeki hukuki ve&nbsp;cezai sorumluluk kendisine aittir. &quot;&Uuml;ye&quot;, &quot;DİKO&rsquo;nun &nbsp;ve/veya başka bir &uuml;&ccedil;&uuml;nc&uuml; şahsın ayni veya&nbsp;şahsi haklarına, malvarlığına tecav&uuml;z teşkil edecek nitelikteki &quot;İnternet sitesi&quot; d&acirc;hilinde bulunan resimleri, metinleri, g&ouml;rsel ve işitsel imgeleri, video klipleri, dosyaları, veri tabanları, katalogları ve listeleri &ccedil;oğaltmayacağını, kopyalamayacağını, dağıtmayacağını, işlemeyeceğini, başka veri tabanına aktarmayacağını veya bu nitelikte sonu&ccedil;lar doğurabilecek şekilde &quot;İnternet sitesine&rdquo; y&uuml;klemeyeceğini; bu t&uuml;r eylemler ger&ccedil;ekleştirerek herhangi bir ticari faaliyette bulunmayacağını; gerek bu eylemleri ile gerekse de başka yollarla doğrudan ve/veya dolaylı olarak haksız rekabet teşkil eden davranış ve&nbsp;işlemler ger&ccedil;ekleştirmeyeceğini kabul ve taahh&uuml;t etmektedir. &quot;&Uuml;yenin işbu &ldquo;&Uuml;yelik S&ouml;zleşmesi&rdquo; h&uuml;k&uuml;mlerine ve&nbsp;hukuka aykırı olarak ger&ccedil;ekleştirdikleri &quot;İnternet sitesi&quot; &uuml;zerindeki faaliyetler nedeniyle &uuml;&ccedil;&uuml;nc&uuml; kişilerin uğradıkları veya uğrayabilecekleri zararlardan dolayı &quot; DİKO &quot; doğrudan ve/veya dolaylı olarak hi&ccedil;bir şekilde sorumlu&nbsp;tutulamaz.</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>5.9:&ldquo;DİKO , &ldquo;&Uuml;yelerin sadece &nbsp;DİKO i&ccedil;eriklerini &ouml;ğrenme amacıyla &nbsp; g&ouml;r&uuml;nt&uuml;lemesine ve &ldquo;DİKO Ara y&uuml;z&uuml;n&uuml; kullanmasına izin vermekte olup, bunun dışında bir ama&ccedil;la veri tabanı &uuml;zerinden belirli bir sayıda veya b&uuml;t&uuml;n&uuml;ne y&ouml;nelik olarak testlere &nbsp;ulaşılmaya &ccedil;alışılması, testlerin kısmen veya tamamen kopyalanması, bunların başka mecralarda doğrudan veya dolaylı olarak yayınlanması, derlenmesi, işlenmesi, başka veri tabanlarına aktarılması, bu veri tabanından &uuml;&ccedil;&uuml;nc&uuml; kişilerin erişimine ve&nbsp;kullanımına a&ccedil;ılması, &ldquo;DİKO &rdquo; &uuml;zerindeki ilanlara link verilmesi de dahil olmak &uuml;zere benzer fiillerin işlenmesine &ldquo;DİKO &rdquo; tarafından izin verilmemekte ve rıza g&ouml;sterilmemektedir. Bu t&uuml;r fiiller hukuka aykırı olup; &quot;DİKO&rsquo; gerekli talep, dava ve&nbsp;takip hakları saklıdır.</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>5.10:DİKO &rdquo;, &ldquo;&Uuml;ye&rdquo; tarafından &ldquo;Siteye beyan edilen &ldquo;&Uuml;ye&rdquo; bilgilerini, Ek-2 Gizlilik Politikası ile a&ccedil;ıklanan kullanımlar, işbu s&ouml;zleşme ile belirlenen y&uuml;k&uuml;ml&uuml;l&uuml;klerini ve&nbsp;&ldquo;Sitenin işletilmesi i&ccedil;in gereken uygulamaların y&uuml;r&uuml;t&uuml;lmesini ifa, kendisi tarafından belirlenen istatistiki değerlendirmeler ve &ldquo;DİKO&rsquo;nun veya işbirliği i&ccedil;inde olduğu kişilerle &nbsp;DİKO, ile aynı grupta yer alan şirketler tarafından ortaya koyulan reklam, tanıtım, anket, kampanya ve doğrudan pazarlama amacıyla gereken s&uuml;relerde kullanabilir ve saklayabilir ve &uuml;&ccedil;&uuml;nc&uuml; kişilere a&ccedil;ıklayabilir. &ldquo;&Uuml;ye&rdquo;, bilgilerinin &ldquo;DİKO &rdquo; tarafından bu şekilde kullanımına ve saklanmasına rıza g&ouml;sterdiğini kabul ve beyan eder.</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>5.11:&ldquo;&Uuml;ye&rdquo;; kendisi tarafından &ldquo;İnternet sitesine&rdquo; sağlanan her t&uuml;rl&uuml; bilgi, i&ccedil;erik, materyal veya verinin vir&uuml;s, spyware, k&ouml;t&uuml; niyetli yazılım,&nbsp;trojan, vs. &ldquo;İnternet sitesine&rdquo; veya herhangi bir &nbsp;par&ccedil;asına zarar verecek nitelikte materyaller i&ccedil;ermemesi i&ccedil;in gerekli her t&uuml;rl&uuml; tedbiri (gerekli anti- vir&uuml;s yazılımlarını kullanmak da dahil olmak &uuml;zere) aldığını kabul, beyan ve&nbsp;taahh&uuml;t&nbsp;eder.</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>5.12:&ldquo;DİKO, &ldquo;İnternet sitesinde&rdquo; sunulan hizmetleri ve&nbsp;i&ccedil;erikleri her zaman ve hi&ccedil;bir bildirimde bulunmadan değiştirebilme; &ldquo;&Uuml;yelerin sisteme y&uuml;kledikleri bilgileri ve i&ccedil;erikleri &ldquo;Kullanıcılar&rdquo; da dahil olmak &uuml;zere &uuml;&ccedil;&uuml;nc&uuml; kişilerin erişimine kapatabilir ve silebilir; &Uuml;yelerin &ldquo;İnternet sitesine&rdquo; y&uuml;klediği i&ccedil;erikleri ve &ldquo;&Uuml;yeler arasındaki &ldquo;İnternet sitesi&rdquo; &uuml;zerinden ger&ccedil;ekleştirilen mesajlaşma da dahil olmak &uuml;zere iletişimi her zaman izleyebilir, kontrol edebilir ve&nbsp;bu iletişimi saklayabilir. &ldquo;&Uuml;ye&rdquo;, &ldquo;İnternet sitesi&rdquo; &uuml;zerinden ger&ccedil;ekleştirdiği t&uuml;m iletişimi; &ldquo;İnternet sitesi&rdquo; &uuml;zerinde ger&ccedil;ekleştirdiği faaliyetler doğrultusunda ger&ccedil;ekleştirdiğini ve&nbsp;bu iletişimi &ouml;zel iletişim amacıyla kullanamayacağını kabul, beyan ve taahh&uuml;t&nbsp;eder.</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>5.13:&ldquo;&Uuml;ye&rdquo;, &ldquo;DİKO &rdquo;&rsquo;nun, &ldquo;&Uuml;ye&rdquo; tarafından &ldquo;İnternet sitesine&rdquo;&rsquo; girilen ilanların, i&ccedil;eriklerin ve&nbsp;g&ouml;rsellerin &uuml;&ccedil;&uuml;nc&uuml; kişilere ait sitelerde ve&nbsp;sistemlerde g&ouml;r&uuml;nt&uuml;lenebilmesi, işlenmesi ve derlenmesi amacıyla anlaşmalar yapabileceğini, &ldquo;&Uuml;ye&rdquo; tarafından &ldquo;İnternet sitesine&rdquo; girilen ilanların, i&ccedil;eriklerin ve g&ouml;rsellerin &ldquo;İnternet sitesinde&rdquo; ve &ldquo;DİKO&rsquo;nun reklam ve tanıtımı i&ccedil;in hazırlanan g&ouml;rsel ve&nbsp;i&ccedil;eriklerde kullanılabileceğini, bu konuda gerekli her t&uuml;rl&uuml; hak ve yetkiyi &ldquo;DİKO&rsquo;ya verdiğini kabul ve beyan&nbsp;eder.</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>5.14:&ldquo;DİKO &rdquo;, &ldquo;&Uuml;yenin işbu s&ouml;zleşme koşullarına ve &ldquo;İnternet sitesinde&rdquo; beyan edilen kural ve şartlara uymaması, &ldquo;&Uuml;yenin siteye girdiği ilan ve i&ccedil;eriklerin işbu s&ouml;zleşme ve&nbsp;&ldquo;İnternet &nbsp;sitesinde&rdquo; yayınlanan kural ve&nbsp;koşullara uygun olmaması, hukuki, teknik ve &ouml;zellikle bilgi g&uuml;venliği anlamında risk oluşturduğunun tespit edilmesi, &ldquo;DİKO &rdquo; tarafından belirlenen i&ccedil;erik kategorileri kapsamında yer almaması veya yanlı b&ouml;l&uuml;mde yer alması, &uuml;&ccedil;&uuml;nc&uuml; kişilerin şahsi ve ticari haklarına halel getirici mahiyette olması sebebiyle, &ldquo;&Uuml;yeye herhangi bir ihtarda bulunmadan ilgili ilanının yayınını ge&ccedil;ici veya s&uuml;rekli olarak durdurabilir. &quot;&Uuml;ye&quot;, &quot;DİKO&rsquo;dan &nbsp; b&ouml;yle bir durumda ilgili &quot;Hizmet&quot; i&ccedil;in &ouml;demiş olduğu bedelin iadesini &quot; talep ve dava etmeyeceğini kabul, beyan ve&nbsp;taahh&uuml;t&nbsp;eder.</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>5.14 :DİKO&quot;, işbu &ldquo;&Uuml;yelik S&ouml;zleşmesinde bahsi ge&ccedil;en hizmetleri; ilgili hizmetlerin sunumuyla ilgili DİKO &Uuml;yelik Hesabı&quot; i&ccedil;erisinde belirtilen a&ccedil;ıklamalar ve işbu &ldquo;&Uuml;yelik S&ouml;zleşmesinde belirtilen koşullar d&acirc;hilinde yerine getirmeyi, işbu &ldquo;&Uuml;yelik S&ouml;zleşmesi&rdquo; kapsamında belirtilen hizmetlerin sunulabilmesi i&ccedil;in gerekli olan teknolojik altyapıyı tesis etmeyi ve işletmeyi kabul, beyan ve&nbsp;taahh&uuml;t eder. İşbu madde i&ccedil;erisinde belirtilen teknolojik altyapı tesis etme y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;, sınırsız ve eksiksiz bir hizmet taahh&uuml;d&uuml; anlamına gelmemektedir; &ldquo;DİKO&rdquo; her zaman herhangi bir bildirimde bulunmadan işbu &ldquo;&Uuml;yelik S&ouml;zleşmesi&rdquo; ile&nbsp;belirlenen hizmetlerini ve&nbsp;teknolojik altyapısını durdurabilir veya son&nbsp;verebilir.</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>5.15:&quot;DİKO &quot;İnternet sitesinde&quot; sunulan hizmetleri ve&nbsp;i&ccedil;erikleri her zaman değiştirebilme; &quot;&Uuml;yelerin sisteme y&uuml;kledikleri bilgileri ve i&ccedil;erikleri &quot;İnternet sitesi&quot; kullanıcıları da d&acirc;hil olmak &uuml;zere &uuml;&ccedil;&uuml;nc&uuml; kişilerin erişimine kapatabilme ve silme hakkını saklı tutmaktadır. &quot;DİKO , bu hakkını hi&ccedil;bir bildirimde bulunmadan ve &ouml;nel vermeden kullanabilir. &quot;&Uuml;yeler, DİKO&rsquo;nun talep ettiği değişiklik ve/veya d&uuml;zeltmeleri ivedi olarak yerine getirmek zorundadırlar. Değişiklik ve/veya d&uuml;zeltmeleri gerekli g&ouml;r&uuml;ld&uuml;ğ&uuml; takdirde &quot;DİKO&quot; yapabilir. &quot;DİKO&quot; tarafından talep edilen değişiklik ve/veya d&uuml;zeltme taleplerinin, kullanıcılar tarafından zamanında yerine getirilmemesi sebebiyle doğan veya doğabilecek zararlar, hukuki ve cezai sorumluluklar tamamen kullanıcılara aittir.</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>5.16:&quot;İnternet sitesi&quot; &uuml;zerinden, &quot;DİKO&rsquo;nun &nbsp;kendi kontrol&uuml;nde olmayan &quot;Satıcılar, sağlayıcılar ve başkaca &uuml;&ccedil;&uuml;nc&uuml; kişilerin sahip olduğu ve&nbsp;işlettiği başka internet sitelerine ve/veya portallara, dosyalara veya i&ccedil;eriklere link verebilir. Bu linkler &quot;&Uuml;yeler tarafından veya sadece referans kolaylığı nedeniyle &nbsp; DİKO &nbsp;tarafından sağlanmış olabilir ve linkin y&ouml;neldiği internet sitesini veya işleten kişisini desteklemek amacıyla veya internet sitesi veya i&ccedil;erdiği bilgilere&nbsp;y&ouml;nelik herhangi bir t&uuml;rde doğrulama beyanı veya garanti niteliği taşımamaktadır. &quot;İnternet sitesi&quot; &uuml;zerindeki linkler vasıtasıyla erişilen portallar, internet siteleri, dosyalar ve i&ccedil;erikler, bu linkler vasıtasıyla erişilen portallar veya internet sitelerinden sunulan hizmetler veya &uuml;r&uuml;nler veya bunların i&ccedil;eriği hakkında &quot;DİKO&rsquo;nun &nbsp;herhangi bir sorumluluğu yoktur.</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>5.17:DİKO &quot;İnternet sitesinin&quot; işleyişine, hukuka, başkalarının haklarına, &ldquo;&Uuml;yelik S&ouml;zleşmesi&rdquo; koşullarına, genel ahlak kurallarına aykırı olan mesajları, i&ccedil;erikleri istediği zaman ve şekilde erişimden kaldırabilir; &quot;DİKO &nbsp;bu mesaj ve i&ccedil;eriği giren &quot;&Uuml;yenin &uuml;yeliğine herhangi bir bildirim yapmadan son&nbsp;verebilir.</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>5.18:DİKO&rsquo;nun , &nbsp;DİKO &ccedil;alışanlarının ve y&ouml;neticilerinin, &ldquo;İnternet sitesinde&rdquo; &ldquo;&Uuml;yeler ve&nbsp;&ldquo;Kullanıcılar tarafından sağlanan i&ccedil;eriklerin hukuka uygun olup olmadığını, ger&ccedil;ekliğini, doğruluğunu araştırmak ve kontrol etmek y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;&nbsp;bulunmamaktadır.</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>5.19: DİKO &nbsp;5651 sayılı &quot;İnternet Ortamında Yapılan Yayınların D&uuml;zenlenmesi ve Bu Yayınlar Yoluyla İşlenen Su&ccedil;larla M&uuml;cadele Edilmesi Hakkında Kanun&quot; kapsamında ve bu Kanun doğrultusunda Telekom&uuml;nikasyon Kurumu İletişim Başkanlığı&rsquo;nın 17.01.2013 tarih ve 581 Belge no&rsquo;lu yazısı ile &quot;Yer Sağlayıcı&quot; olarak faaliyet&nbsp;g&ouml;stermektedir.</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>6-HİZMET BEDELİ VE &Ouml;DEME</span></strong></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>6.1:Hizmet bedelinin &ouml;denmesi kapsamında &uuml;yeler tarafından sağlanan kredi kartı bilgileri kesinlikle şirket nezdinde saklanmamaktadır. &Uuml;yelerin site &uuml;zerinden ger&ccedil;ekleştirdikleri &ouml;deme işlemlerinde kullandığı kredi kartı ve &ouml;deme bilgileri Diko&rsquo;nun bu hususta hizmet aldığı, Bankacılık &nbsp;D&uuml;zenleme ve Denetleme Kurumu tarafından &ouml;deme hizmetleri sağlamak &uuml;zere &nbsp;yetkilendirilmiş bulunan &uuml;&ccedil;&uuml;nc&uuml; kişi firmalarca saklanacak olup ilgili &uuml;ye bu hususa peşinen muvafakat vermektedir.</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>6.2:Bu kapsamda &uuml;ye, kredi kartı ve &ouml;deme bilgilerinin saklanması, kullanılması ve yetkisiz kullanımı da dahil her t&uuml;rl&uuml; talep ve şikayetlerini doğrudan &uuml;&ccedil;&uuml;nc&uuml; kişi kuruluşa bildirmekle y&uuml;k&uuml;ml&uuml;d&uuml;r. &Uuml;ye bahsi ge&ccedil;en talep ve şikayetlerden Diko&rsquo;nun &nbsp;herhangi bir sorumluluğunun olmadığını kabul, beyan ve taahh&uuml;t eder.</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>7-GİZLİLİK POLİTİKASI</span></strong></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>7.1:&ldquo;DİKO &rdquo;, &quot;İnternet sitesinde&rdquo; &quot;&Uuml;yelerle ilgili bilgileri işbu &ldquo;&Uuml;yelik S&ouml;zleşmesi&rdquo; ve işbu &ldquo;&Uuml;yelik S&ouml;zleşmesinin eklerinden biri ve&nbsp;ayrılmaz par&ccedil;ası olan EK-2&rsquo;teki Gizlilik Politikası kapsamında kullanabilir. &ldquo;DİKO &rdquo; &quot;&Uuml;yelere ait gizli bilgileri ancak Gizlilik Politikasında belirtilen koşullar d&acirc;hilinde &uuml;&ccedil;&uuml;nc&uuml; kişilere a&ccedil;ıklayabilir veya kullanabilir.</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>7.2:DİKO &nbsp;&ldquo;&Uuml;yelik S&ouml;zleşmesi&rdquo; ile belirlenen ama&ccedil; ve&nbsp;kapsam dışında da &ldquo;&Uuml;ye&rdquo; olma aşamasında talep edilen ad-soyad, telefon numarası, e-mail adresi gibi bilgileri; y&uuml;r&uuml;rl&uuml;kte bulunan mevzuatta &ouml;ng&ouml;r&uuml;len koşullara tabi şekilde ve gerektiğinde ek onayları almak suretiyle sms, e-mail, site i&ccedil;i bilgilendirmeler ve benzeri y&ouml;ntemlerle, tanıtım ve bilgilendirme ama&ccedil;lı iletişim faaliyetleri, araştırma, pazarlama faaliyetleri ve&nbsp;istatistik&icirc; analizler yapmak amacıyla veya gerektiğinde &ldquo;&Uuml;ye&rdquo; &nbsp;ile temas kurmak i&ccedil;in kullanabilir. Yine y&uuml;r&uuml;rl&uuml;kte bulunan mevzuat uyarınca kişisel veriler aynı zamanda&rdquo;&rsquo;nin s&uuml;re&ccedil;lerini iyileştirme &nbsp;</span><a href="http://www.dikotr.com"><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>www.dikotr.com</span></a><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;ama&ccedil;lı araştırmalar yapmak, veri tabanı oluşturmak ve&nbsp;pazar araştırmaları yapmak i&ccedil;in &ldquo;DİKO&rsquo;nun işbirliği i&ccedil;inde olduğu firmalara aktarılabilir, bu firmalar tarafından işlenebilir ve&nbsp;kullanılabilir. &ldquo;DİKO &lsquo;nun belirtilen ama&ccedil;larla işbirliği i&ccedil;inde bulunduğu ger&ccedil;ek kişi ve/veya t&uuml;zel kişilere, Kullanıcıların kişisel verilerini iletmesine Kullanıcılar onay&nbsp;vermektedir.</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>8-FİKRİ M&Uuml;LKİYET HAKLARI</span></strong></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>8.1:İnternet sitesi&rdquo;nin (aray&uuml;z, ilan veri tabanı, i&ccedil;erik veri tabanı, tasarım, metin, imge, html kodu ve diğer kodlar da dahil ve fakat bunlarla sınırlı olmamak kaydıyla) t&uuml;m elemanları &ldquo;DİKO&rsquo;ya ait ve/veya DİKO tarafından &uuml;&ccedil;&uuml;nc&uuml; bir kişiden alınan lisans hakkı altında kullanılmaktadır.</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>8.2:DİKO uygulamalarının, &ldquo;İnternet sitesinde&rdquo; yer alan bilgilerin ve DİKO&rsquo;nun telif haklarına tabi &ccedil;alışmalarının yeniden satışı, paylaşılması, dağıtılması, sergilenmesi ve&nbsp;&uuml;&ccedil;&uuml;nc&uuml; kişilerin erişimine sunulmasına DİKO tarafından izin verilmemektedir. İzin verilmeyen kullanımı ger&ccedil;ekleştiren kişiler, DİKO&rsquo;nun ve &uuml;&ccedil;&uuml;nc&uuml; kişilerin uğradıkları zararları mahkeme masrafları ve avukatlık &uuml;creti de dahil olmak &uuml;zere karşılamakla y&uuml;k&uuml;ml&uuml; olacaklardır. DİKO ; &ldquo;İnternet sitesinde&rdquo; ve&nbsp;&ldquo;İnternet sitesi&rdquo; &uuml;zerindeki uygulamaları ve&nbsp;i&ccedil;erikleri, bilgileri, telif haklarına tabi &ccedil;alışmaları, ticari markaları, ticari g&ouml;r&uuml;n&uuml;m&uuml; veya Site vasıtasıyla sahip olduğu her t&uuml;rl&uuml; maddi ve fikri m&uuml;lkiyet hakları da dahil t&uuml;m malvarlığı, ayni ve şahsi hak, ticari bilgi ve know-how&rsquo;a y&ouml;nelik t&uuml;m hakları&nbsp;saklıdır.</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>9-&Uuml;YELİK S&Ouml;ZLEŞMESİ DEĞİŞİKLİKLERİ</span></strong></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>DİKO , tamamen kendi takdirine bağlı ve tek taraflı olarak işbu &ldquo;&Uuml;yelik S&ouml;zleşmesi&rdquo;ni uygun g&ouml;receği herhangi bir zamanda &quot;İnternet sitesinde&rdquo; yayınlayarak değiştirebilir. İşbu &ldquo;&Uuml;yelik</span><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;S&ouml;zleşmesinin değişen h&uuml;k&uuml;mleri, &ldquo;İnternet sitesinde&rdquo; yayınlandığı tarihte ge&ccedil;erlilik kazanarak y&uuml;r&uuml;rl&uuml;ğe girecek, geri kalan h&uuml;k&uuml;mler aynen y&uuml;r&uuml;rl&uuml;kte kalarak h&uuml;k&uuml;m ve sonu&ccedil;larını doğurmaya devam edecektir. İşbu &ldquo;&Uuml;yelik S&ouml;zleşmesi&rdquo;, &quot;&Uuml;yenin tek taraflı beyanları ile değiştirilemez.</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>10- M&Uuml;CBİR SEBEPLER</span></strong></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>10.1:Hukuken m&uuml;cbir sebep sayılan t&uuml;m durumlarda, DİKO işbu &ldquo;&Uuml;yelik S&ouml;zleşmesi&rdquo; ile&nbsp;belirlenen edimlerinden herhangi birini ge&ccedil; veya eksik ifa etme veya ifa etmeme nedeniyle y&uuml;k&uuml;ml&uuml; değildir. Bu ve&nbsp;bunun gibi durumlar, &quot;DİKO &quot; i&ccedil;in, gecikme, eksik ifa etme veya ifa etmeme veya temerr&uuml;t addedilmeyecek veya bu durumlar i&ccedil;in &quot;DİKO &quot;dan herhangi bir ad altında tazminat talep edilemeyecektir.&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>10.2:M&uuml;cbir sebep terimi; doğal afet, salgın hastalık, pandemi, isyan, savaş, grev, iletişim sorunları, altyapı ve internet arızaları, sisteme ilişkin iyileştirme veya yenileştirme &ccedil;alışmaları ve bu sebeple meydana gelebilecek arızalar, elektrik kesintisi ve&nbsp;k&ouml;t&uuml; hava koşulları da d&acirc;hil ve fakat bunlarla sınırlı olmamak kaydıyla ilgili tarafın makul kontrol&uuml; haricinde ve DİKO gerekli &ouml;zeni g&ouml;stermesine rağmen &ouml;nleyemediği ka&ccedil;ınılamayacak olaylar olarak&nbsp;yorumlanacaktır.</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>11-UYGULANACAK HUKUK VE YETKİ</span></strong></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>İşbu &ldquo;&Uuml;yelik S&ouml;zleşmesinin uygulanmasında, yorumlanmasında ve işbu &ldquo;&Uuml;yelik S&ouml;zleşmesi&rdquo; d&acirc;hilinde doğan hukuki ilişkilerin y&ouml;netiminde yabancılık unsuru bulunması durumunda T&uuml;rk kanunlar ihtilafı kuralları hari&ccedil; olmak &uuml;zere T&uuml;rk Hukuku uygulanacaktır.&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>İşbu &ldquo;&Uuml;yelik S&ouml;zleşmesi&rdquo;nden dolayı doğan veya doğabilecek her t&uuml;rl&uuml; ihtilafın halinde &nbsp;Kocaeli &nbsp;Mahkemeleri ve İcra Daireleri yetkilidir</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>12-&quot;DİKO&quot; Kayıtlarının&nbsp;Ge&ccedil;erliliği</span></strong></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&quot;&Uuml;ye&quot;, işbu &ldquo;&Uuml;yelik S&ouml;zleşmesinden doğabilecek ihtilaflarda DİKO&rsquo;nun kendi veri tabanında, sunucularında tuttuğu elektronik ve sistem kayıtlarının, ticari kayıtlarının, defter kayıtlarının, mikrofilm, mikrofiş ve bilgisayar kayıtlarının muteber bağlayıcı, kesin ve m&uuml;nhasır delil teşkil edeceğini,&quot;DİKO&rsquo;ya &nbsp;yemin teklifinden beri kıldığını ve bu maddenin HMK 193. Madde anlamında delil s&ouml;zleşmesi niteliğinde olduğunu kabul, beyan ve taahh&uuml;t eder.</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>12-Y&uuml;r&uuml;rl&uuml;k</span></strong></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>İşbu &ldquo;&Uuml;yelik S&ouml;zleşmesi&rdquo; ve&nbsp;&ldquo;&Uuml;yelik S&ouml;zleşmesiyle &nbsp;atıfta bulunulan ve&nbsp;&ldquo;&Uuml;yelik S&ouml;zleşmesinin&nbsp;</span></p>
    <p style='margin-top:0in;margin-right:5.3pt;margin-bottom:0in;margin-left:0in;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:115%;font-family:"Times New Roman",serif;'>ayrılmaz bir par&ccedil;ası olan ekler ile &ldquo;İnternet sitesinde&rdquo; yer alan kurallar ve&nbsp;şartlar, &quot;&Uuml;yenin elektronik olarak onay vermesi ile elektronik ortamda akdedilerek y&uuml;r&uuml;rl&uuml;ğe girmiştir. &ldquo;&Uuml;yelik S&ouml;zleşmesinin herhangi bir h&uuml;km&uuml;n&uuml;n ge&ccedil;ersizliği, mevzuata aykırılığı veya uygulanabilir olmaması s&ouml;zleşmenin geri kalan h&uuml;k&uuml;mlerinin y&uuml;r&uuml;rl&uuml;ğ&uuml;n&uuml;&nbsp;etkilemeyecektir.</span></p>
    <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>`,
	},
};
