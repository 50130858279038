var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v("Diko")])]),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" Diko Terapi'ye Kayıt Ol!")]),_c('b-card-text',{staticClass:"mb-2"}),_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2"},[_c('b-form-group',{attrs:{"label":"Ad Soyad","label-for":"register-username"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Username'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-username","name":"register-username","state":errors.length > 0 ? false : null,"placeholder":""},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0]).replaceAll("Username", "Ad Soyad")))])]}}])})],1),_c('b-form-group',{attrs:{"label":"E-Posta","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false : null,"placeholder":"E-Posta Adresinizi Giriniz..."},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0]).replaceAll("Email", "E-Posta")))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":"Şifre"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Password'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0]).replaceAll("Password", "Şifre")))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("Uzmanlık Alanı")]),_c('b-form-select',{model:{value:(_vm.field),callback:function ($$v) {_vm.field=$$v},expression:"field"}},[_c('b-form-select-option',{attrs:{"value":""}},[_vm._v("Seçiniz")]),_c('b-form-select-option',{attrs:{"value":"psychiatry"}},[_vm._v("Psikiyatri")]),_c('b-form-select-option',{attrs:{"value":"psychology"}},[_vm._v("Psikoloji")]),_c('b-form-select-option',{attrs:{"value":"counseling"}},[_vm._v("PDR")]),_c('b-form-select-option',{attrs:{"value":"other"}},[_vm._v("Diğer")])],1)],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('Privacy'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_c('b-link',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-center",modifiers:{"modal-center":true}}]},[_vm._v("Kullanıcı Sözleşmesi & Gizlilik Metnini")]),_vm._v(" Okudum Onaylıyorum ")],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1),_c('b-button',{attrs:{"variant":"primary","title":!_vm.status ||
                _vm.field == '' ||
                _vm.password == '' ||
                _vm.userEmail == '' ||
                _vm.username == ''
                  ? _vm.$t('You must agree to Terms of Privacy')
                  : '',"disabled":!_vm.status ||
                _vm.field == '' ||
                _vm.password == '' ||
                _vm.userEmail == '' ||
                _vm.username == '',"block":"","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Kayıt Ol! ")])],1)],1),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Üyeliğiniz Var Mı?")]),_c('b-link',{attrs:{"to":{ name: 'auth-login-v2' }}},[_c('span',[_vm._v(" Giriş Yapın")])])],1)],1)],1)],1),_c('b-modal',{staticStyle:{"overflow-x":"hidden"},attrs:{"id":"modal-center","scrollable":"","title":_vm.privacy.privacy.title,"ok-only":"","ok-title":"Kabul Et","size":"lg"},on:{"ok":_vm.privacyOk}},[_c('b-card-text',{staticStyle:{"width":"100%"},domProps:{"innerHTML":_vm._s(_vm.privacy.privacy.content)}},[_vm._v(" "+_vm._s(_vm.privacy.privacy.content)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }